@import '../variables';

.btn {
  position: relative;
}

.btn.loading .loading-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.btn .loading-icon {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
