.trustpilot {
  height: min-content;
  display: flex;
  align-items: center;
  color: black;
}

.stars,
.logo {
  margin-left: 10px;
}
.stars {
  height: 1rem;
}

.logo {
  height: 1.75rem;
}
