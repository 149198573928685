@import '_variables';

.footer {
  color: white;
  background-color: $theme-primary;
  margin-top: auto;

  padding: 1rem 3rem;
}

.footer-nav {
  a {
    color: white;
    text-decoration: none;
    margin-left: 2rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.footer-column {
  flex: 1 1 0;
}
