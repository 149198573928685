@import '../variables';
@import 'bootstrap/scss/bootstrap-utilities';

.location-card {
  padding: 1rem 1rem;

  .text-accent {
    color: $theme-primary;
    font-size: 0.9rem;
  }
  .text-location {
    font-size: 0.8rem;
  }
  .store-button {
    white-space: nowrap;
  }
  .card-content-column {
    padding-right: 15px;
  }

  .card-title {
    font-weight: 600;
  }

  &.active {
    border-width: 2px;
    border-color: $theme-accent;
  }

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

.location-form {
  display: grid;
  grid-template: 'a b c' auto / 5fr 3fr 2fr;
  grid-gap: 0 15px;
  margin-top: 2.2rem;
  margin-bottom: 2rem;
  height: 3.5rem;

  .postcode-input {
    grid-area: a;
  }
  .date-input {
    grid-area: b;
    display: flex;
  }
  .form-submit {
    grid-area: c;
  }

  @include media-breakpoint-down(sm) {
    grid-gap: 10px;
    grid-template: 'a a'
                   'b c';
    height: 5rem;
  }
}

.location-list-column {
  padding: 2rem 2rem 0 2rem;

  @media (max-width: 1024px) {
    padding: 20px;
  }
}

.map-back {
  position: absolute;
  top: 1rem;
  left: 0;
  z-index: 10;
  background-color: white;
  padding: 0.5rem;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
}

.map-all {
  position: absolute;
  top: 1rem;
  right: 0;
  z-index: 10;
  background-color: white;
  padding: 0.5rem;
  border-radius: 8px 0 0 8px;
  cursor: pointer;
}

.location-map-container {
  --bs-gutter-x: 0;

  @include media-breakpoint-down(sm) {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 100%;

    transition: left 0.4s ease-in-out;

    &.show {
      left: 0;
    }
  }
}

.search-input {
  margin-top: 0.8rem;
  border-radius: 8px;
  padding: 1.4rem;
  font-style: italic;
  font-weight: lighter;
  box-shadow: 0 0 0.5rem lightgrey;
  border-width: 0;
}

.input::placeholder {
  color: darken(lightgrey, 10%);
  font-size: 0.975rem;
  font-weight: 200;
  font-style: italic;
}

.search-movein-wrapper {
  margin-right: 1rem;
  > div {
    height: 100%;
  }
}
