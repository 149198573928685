@import '_variables';

.quote-step {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:first-child {
    margin-left: auto;
  }
  &:last-child {
    margin-right: auto;
  }

  .quote-step--badge {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    border-radius: 5px;

    margin-right: 15px;

    font-family: 'Poppins', Arial, sans-serif;
    font-weight: 700;

    color: #8c8c8c;
    background-color: #f4f4f4;

    &.active {
      color: white;
      background-color: $theme-accent;
      box-shadow: 0 0 0.5rem 0 lighten($theme-accent, 10);
    }

    &.valid {
      color: white;
      background-color: $theme-accent;
    }
  }

  .quote-step--name {
    margin-left: 0.5rem;
  }
}